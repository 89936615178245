import React from 'react';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {MemberAddressStepState} from '../../../../../../types/app.types';
import {MemberBillingAddressTitle} from '../BillingAddressTitle/MemberBillingAddressTitle';
import {MemberContactAndAddressSummary} from '../../../../ContactAndAddressSummary/MemberContactAndAddressSummary';
import {classes} from './MemberBillingDetails.st.css';
import {BillingDetailsForm} from './BillingDetailsForm/BillingDetailsForm';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';

export enum MemberBillingDetailsDataHook {
  root = 'MemberBillingDetails.root',
  edit = 'MemberBillingDetails.edit',
  new = 'MemberBillingDetails.new',
  changeButton = 'MemberBillingDetails.changeButton',
  editButton = 'MemberBillingDetails.editButton',
}

export const MemberBillingDetails = () => {
  const {selectedAddressesServiceId, memberAddressStepState} = useBillingData();

  return (
    <div>
      <MemberBillingAddressTitle />
      <div data-hook={MemberBillingDetailsDataHook.root} className={classes.root}>
        {memberAddressStepState !== MemberAddressStepState.COLLAPSED && <BillingAddressDropdown />}
        {selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID &&
          memberAddressStepState !== MemberAddressStepState.EDIT && <MemberContactAndAddressSummary />}
        {memberAddressStepState === MemberAddressStepState.EDIT && (
          <div data-hook={MemberBillingDetailsDataHook.edit}>
            <BillingDetailsForm />
          </div>
        )}
        {selectedAddressesServiceId === ADD_NEW_ADDRESS_ID && (
          <div data-hook={MemberBillingDetailsDataHook.new}>NEW (TEMPORARY)</div>
        )}
      </div>
    </div>
  );
};
