import React, {useMemo} from 'react';
import {classes} from './MamberContactAndAddressSummary.st.css';
import {ContactAndAddressSummary} from './ContactAndAddressSummary';
import {useBillingData} from '../CheckoutSteps/Steps/PaymentStep/WithBillingData/WithBillingData';
import {MemberAddressStepState} from '../../../types/app.types';
import {TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {MemberBillingDetailsDataHook} from '../CheckoutSteps/Steps/PaymentStep/BillingDetails/MemberBillingDetails';
import {useControllerProps} from '../Widget/ControllerContext';

export const MemberContactAndAddressSummary = () => {
  const localeKeys = useLocaleKeys();
  const {
    memberStore: {addressesInfo},
  } = useControllerProps();

  const {selectedAddressesServiceId, memberAddressStepState, setMemberAddressStepState, initForm} = useBillingData();
  const billingInfo = useMemo(
    () => addressesInfo.addresses?.find((address) => address.addressesServiceId === selectedAddressesServiceId),
    [selectedAddressesServiceId, addressesInfo]
  );
  const initMemberAddressStepState = () => {
    setMemberAddressStepState(MemberAddressStepState.EDIT);
    initForm(billingInfo);
  };

  return (
    <div className={classes.container}>
      <ContactAndAddressSummary contact={billingInfo?.contact} address={billingInfo?.address} />
      {memberAddressStepState === MemberAddressStepState.OPEN && (
        <TextButton
          key={'textButton'}
          data-hook={MemberBillingDetailsDataHook.editButton}
          className={classes.editTitle}
          onClick={initMemberAddressStepState}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
