import React, {ComponentType, createContext, useContext, useEffect, useRef, useState} from 'react';
import {FormHandle, FormValues} from '@wix/form-viewer';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormViewerHandle, FormError} from '@wix/form-viewer/widget';
import {FullAddressContactDetails, ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {CashierMandatoryFieldsOverrides, MemberAddressStepState} from '../../../../../../types/app.types';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {getVatFormInitialState, getVatFromVatFormValues} from '../../../../Form/VatIdForm/VatForm.utils';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';

interface FormInstanceData {
  data: {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
    formErrors: FormError[];
    setFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
    formRef: React.RefObject<FormHandle>;
  };
  isValid: () => Promise<boolean>;
  isRendered: () => boolean;
}

interface BillingFormData {
  contact: FormInstanceData;
  vat: FormInstanceData;
  address: FormInstanceData;
  isFormValid: () => Promise<boolean>;
  initForm: (billingInfo?: AddressWithContactModel) => void;
  updateContactCountry: (country: string) => void;
  getDataForSubmit: () => {contactDetails?: FullAddressContactDetails; address?: ApiAddress};
}

export type BillingDataContextType = {
  billingFormData: BillingFormData;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  setCashierMandatoryFields: React.Dispatch<React.SetStateAction<CashierMandatoryFieldsOverrides>>;
  setBillingSameAsShipping: React.Dispatch<React.SetStateAction<boolean>>;
  billingSameAsShipping: boolean;
  selectedAddressesServiceId?: string;
  setSelectedAddressesServiceId: (id: string) => void;
  memberAddressStepState: MemberAddressStepState;
  setMemberAddressStepState: (state: MemberAddressStepState) => void;
  resetMemberAddressState: () => void;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      memberStore: {addressesInfo, isAddressesAppInstalled, isMember},
    } = useControllerProps();

    const billingFormData = useBillingFormData();

    const [cashierMandatoryFields, setCashierMandatoryFields] = useState<CashierMandatoryFieldsOverrides>({});

    const getInitialSelectedAddressesServiceId = (): string | undefined => {
      if (checkout.billingInfo?.addressesServiceId) {
        return checkout.billingInfo?.addressesServiceId;
      }

      if (isMember && isAddressesAppInstalled && addressesInfo.addresses.length === 0) {
        return ADD_NEW_ADDRESS_ID;
      }
    };

    const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(true);
    const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);
    const [memberAddressStepState, setMemberAddressStepState] = useState(MemberAddressStepState.COLLAPSED);

    useEffect(() => {
      if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
        setMemberAddressStepState(MemberAddressStepState.OPEN);
      }
    }, [selectedAddressesServiceId, memberAddressStepState]);

    const resetMemberAddressState = () => {
      setMemberAddressStepState(MemberAddressStepState.COLLAPSED);
      setSelectedAddressesServiceId(getInitialSelectedAddressesServiceId());
    };

    return (
      <BillingDataContext.Provider
        value={{
          billingFormData,
          cashierMandatoryFields,
          setCashierMandatoryFields,
          setBillingSameAsShipping,
          billingSameAsShipping,
          selectedAddressesServiceId,
          setSelectedAddressesServiceId,
          memberAddressStepState,
          setMemberAddressStepState,
          resetMemberAddressState,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {
    billingFormData,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    setBillingSameAsShipping,
    billingSameAsShipping,
    selectedAddressesServiceId,
    setSelectedAddressesServiceId,
    memberAddressStepState,
    setMemberAddressStepState,
    resetMemberAddressState,
  } = useContext(BillingDataContext);

  return {
    contactFormData: billingFormData.contact.data,
    vatFormData: billingFormData.vat.data,
    addressFormData: billingFormData.address.data,

    isFormValid: billingFormData.isFormValid,
    initForm: billingFormData.initForm,
    updateContactCountry: billingFormData.updateContactCountry,
    getBillingFormDataForSubmit: billingFormData.getDataForSubmit,

    cashierMandatoryFields,
    setCashierMandatoryFields,

    setBillingSameAsShipping,
    billingSameAsShipping,

    selectedAddressesServiceId,
    setSelectedAddressesServiceId,

    memberAddressStepState,
    setMemberAddressStepState,

    resetMemberAddressState,
  };
}

function useBillingFormData(): BillingFormData {
  const {
    checkoutStore: {checkout},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const contactForm = useFormInstance(
    getContactFormInitialState({
      checkoutSettings,
      contact: checkout.billingInfo?.contact,
      country: checkout.billingInfo?.address?.country,
    })
  );
  const vatForm = useFormInstance(getVatFormInitialState(checkout.billingInfo?.contact));
  const addressForm = useFormInstance(getAddressFormInitialState(checkoutSettings, checkout.billingInfo?.address));

  const forms = [contactForm, vatForm, addressForm];

  const isFormValid = async () => {
    const areFormsValidArr = await Promise.all(forms.map((ref) => ref.isValid()));

    return !areFormsValidArr.includes(false);
  };

  const initForm = (billingInfo: AddressWithContactModel | undefined = checkout.billingInfo) => {
    contactForm.data.setFormValues(
      getContactFormInitialState({
        checkoutSettings,
        contact: billingInfo?.contact,
        country: billingInfo?.address?.country,
      })
    );
    vatForm.data.setFormValues(getVatFormInitialState(billingInfo?.contact));
    addressForm.data.setFormValues(getAddressFormInitialState(checkoutSettings, billingInfo?.address));
  };

  const areFormsRendered = () => forms.some((form) => form.isRendered());

  const updateContactCountry = (country: string) => {
    contactForm.data.setFormValues(
      getContactFormCurrentStateWithUpdatedCountry({
        contactFormValues: contactForm.data.formValues,
        country,
      })
    );
  };

  const getDataForSubmit = (): {contactDetails?: FullAddressContactDetails; address?: ApiAddress} => {
    if (!areFormsRendered()) {
      return {};
    }

    const contactDetails = getContactDetailsFromContactFormValues(contactForm.data.formValues, checkoutSettings);
    const address = getAddressFromAddressFormValues(checkoutSettings, addressForm.data.formValues);
    const vatId = getVatFromVatFormValues(vatForm.data.formValues);

    return {
      contactDetails: {
        ...contactDetails,
        ...(vatId ? {vatId} : {}),
      },
      address,
    };
  };

  return {
    contact: contactForm,
    vat: vatForm,
    address: addressForm,
    isFormValid,
    initForm,
    updateContactCountry,
    getDataForSubmit,
  };
}

function useFormInstance(init: FormValues): FormInstanceData {
  const [formValues, setFormValues] = useState<FormValues>(init);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const formRef = useRef<FormViewerHandle>(null);

  const isValid = async () => {
    if (formRef.current) {
      return formRef.current.validate();
    }

    return true;
  };

  return {
    data: {
      formValues,
      setFormValues,
      formErrors,
      setFormErrors,
      formRef,
    },
    isValid,
    isRendered: () => !!formRef.current,
  };
}
