import React from 'react';
import {classes} from './BillingDetails.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {MemberBillingDetails} from './MemberBillingDetails';
import {VisitorBillingDetails} from './VisitorBillingDetails';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
}

export const BillingDetails = () => {
  const {
    memberStore: {isMember, isAddressesAppInstalled},
  } = useControllerProps();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      {isMember && isAddressesAppInstalled ? <MemberBillingDetails /> : <VisitorBillingDetails />}
    </div>
  );
};
